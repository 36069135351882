    <container>

        <div class="body p-30 set-width" *ngIf="isCodeValid">
            <div class="enrollment_main">
                <div class="container">
                    <div class="enrollment_flex">
    
                        <div class="cip-heading text-center"> {{'AdditionalVarification' | translate}}  </div>
                        <div class="av-text">
                            <p> {{'AdditionalVarification.PasswordPolicyText' | translate}} </p>
                            <p> {{policyString}} </p>
                        </div>
                        <div class="col-md-12 p-0">
                            <div class="cip-lang">
                                <div class="cip-lang-data">

                                    <form [formGroup]="form" (ngSubmit)="submit(form.value)">
                                        <div class="form-group">
                                            <label for=""> {{'AdditionalVarification.Last4Digits' | translate}} </label>
                                            <div>
                                                <input type="text" mask="0000" placeholder="{{'AdditionalVarification.Last4Digits' | translate}}"
                                                    formControlName="CardLast4Digits" autofocus required class="form-control form-input" autocomplete="off"
                                                    [ngClass]="{ 'is-invalid': control?.CardLast4Digits?.touched && control?.CardLast4Digits?.errors }" />
                                                <div *ngIf="control?.CardLast4Digits?.errors" class="invalid-feedback">
                                                    <div *ngIf="control?.CardLast4Digits?.errors?.required"> {{'AdditionalVarification.PleaseEnterLast4Digits' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for=""> {{'AdditionalVarification.EnterDateOfBirth' | translate}} </label>
                                            <div>
                                                <input placeholder="MM/DD/YY" maxlength="8" required class="form-control form-input" 
                                                    #fromDateDP="bsDatepicker" bsDatepicker formControlName="DateOfBirth" [bsConfig]="bsConfig"
                                                    [ngClass]="{ 'is-invalid': control?.DateOfBirth?.touched && control?.DateOfBirth?.errors }" />
                                                <i (click)="fromDateDP.toggle()" class="fa fa-calendar"></i>
                                            
                                            <div *ngIf="control?.DateOfBirth?.errors" class="invalid-feedback">
                                                <div *ngIf="control?.DateOfBirth?.errors?.required"> {{'AdditionalVarification.Required' | translate}} </div>
                                            </div>
                                            <div *ngIf="isDateFromError" class="invalid-feedback">
                                                <div> {{'DASHBOARD.CARD.RA.ValidDate' | translate}} </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="form-group">
                                            <label for=""> {{'DASHBOARD.SETTINGS.CP.NewPassword' | translate }} </label>
                                            <div>
                                                <input [minlength]=8 [maxlength]=20 type="password" placeholder="********"
                                                    formControlName="newPassword" required class="form-control form-input" autocomplete="off"
                                                    (blur)="matchPassword(form.value.newPassword, form.value.confirmPassword)"
                                                    [ngClass]="{ 'is-invalid': control?.newPassword?.touched && control?.newPassword?.errors }" />
                                                <div *ngIf="control?.newPassword?.errors?.required" class="invalid-feedback">
                                                    <div> {{'DASHBOARD.SETTINGS.CP.NewPasswordIsRequired' | translate }} </div>
                                                </div>
                                                <div *ngIf="control.newPassword?.errors && !control.newPassword?.errors?.required && control?.newPassword?.errors?.minlength" class="invalid-feedback">
                                                    <div> {{'DASHBOARD.SETTINGS.CP.NewPasswordIsMinlength' | translate }} </div>
                                                </div>
                                                <div *ngIf="control.newPassword?.errors && !control.newPassword?.errors?.required && control.newPassword?.errors.maxlength" class="invalid-feedback">
                                                    <div> {{'DASHBOARD.SETTINGS.CP.NewPasswordIsMaxlength' | translate }} </div>
                                                </div>
                                                <password-strength-bar [passwordToCheck]="control.newPassword.value"></password-strength-bar>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for=""> {{'DASHBOARD.SETTINGS.CP.ConfirmPassword' | translate }} </label>
                                            <div>
                                                <input type="password" placeholder="********"
                                                    formControlName="confirmPassword" required class="form-control form-input" autocomplete="off"
                                                    (blur)="matchPassword(form.value.newPassword, form.value.confirmPassword)"
                                                    [ngClass]="{ 'is-invalid': control?.confirmPassword?.touched && (control?.confirmPassword?.errors || control?.confirmPassword?.errors?.notMatched) }" />
                                                <div *ngIf="control?.confirmPassword?.errors?.required" class="invalid-feedback">
                                                    <div> {{'DASHBOARD.SETTINGS.CP.ConfirmPasswordIsRequired' | translate }} </div>
                                                </div>
                                                <div *ngIf="control.confirmPassword?.errors && !control.confirmPassword?.errors?.required" class="invalid-feedback">
                                                    <div>{{'DASHBOARD.SETTINGS.CP.PasswordDoNotMatch' | translate }} </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-button">
                                            <div></div>
                                            <div>
                                                <button type="submit" save-btn [ngClass]="!form.invalid && !matchPasswordError ? 'green-btn' : 'green-btnDisable'"> {{'AdditionalVarification.CONFIRM' | translate}} </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                        <!-- <div class="col-md-12 p-0">
                            <div class="cip-lang">
                                <div class="cip-lang-data">
                                    <form [formGroup]="form" (ngSubmit)="submit(form.value)">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="change-p-data m-0">
                                                    <mat-form-field class="example-full-width">
                                                        <mat-label> {{'AdditionalVarification.Last4Digits' | translate}} </mat-label>
                                                        <input class="font-raleway" type="text" mask="0000" placeholder="Last 4 Digits of Your Card"
                                                            formControlName="CardLast4Digits" matInput required autocomplete="off">
                                                        <mat-error class="mt-5" *ngIf="f.CardLast4Digits?.errors?.required">
                                                            {{'AdditionalVarification.PleaseEnterLast4Digits' | translate}} </mat-error>
                                                    </mat-form-field>
                                                </div>
                                    
                                                <div class="change-p-data m-0">
                                                    <mat-form-field class="example-full-width">
                                                        <mat-label> {{'AdditionalVarification.EnterDateOfBirth' | translate}} </mat-label>
                                                        <input class="font-raleway" type="text" placeholder="MM/DD/YY" maxlength="8"
                                                            #fromDateDP="bsDatepicker" bsDatepicker formControlName="DateOfBirth" [bsConfig]="bsConfig"
                                                            matInput required autocomplete="off">
                                                        <mat-icon matSuffix (click)="fromDateDP.toggle()" [attr.aria-expanded]="fromDateDP.isOpen"
                                                            aria-label="fromDateCalendar" title="calendar">calendar_today</mat-icon>
                                                        <mat-error class="mt-5" *ngIf="isDateFromError"> {{'DASHBOARD.CARD.RA.ValidDate' | translate}}
                                                        </mat-error>
                                                        <mat-error class="mt-5" *ngIf="f.CardLast4Digits?.errors?.required">
                                                            {{'AdditionalVarification.Required' | translate}} </mat-error>
                                                    </mat-form-field>
                                                </div>
                                    
                                                <div class="change-p-data m-0">
                                                    <label> Enter Date Of Birth </label>
                                                    <div class="input-group input-date">
                                                        <input class="form-control form-input-box" placeholder="MM/DD/YY" maxlength="8"
                                                            #fromDateDP="bsDatepicker" bsDatepicker formControlName="DateOfBirth" [bsConfig]="bsConfig">
                                                        <div class="input-group-append">
                                                            <button class="btn btn-calendar" (click)="fromDateDP.toggle()"
                                                                [attr.aria-expanded]="fromDateDP.isOpen" type="button" aria-label="fromDateCalendar"
                                                                title="calendar">
                                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                        <div class="input-error-label">
                                                            <div *ngIf="isDateFromError"> {{'DASHBOARD.CARD.RA.ValidDate' | translate}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                    
                                                    <div class="change-p-data error-xtra-space">
                                                        <mat-form-field class="example-full-width">
                                                            <mat-label> {{'DASHBOARD.SETTINGS.CP.NewPassword' | translate }} </mat-label>
                                                            <input class="font-raleway" [minlength]=8 [maxlength]=20 type="password" placeholder="********"
                                                                formControlName="newPassword"
                                                                (blur)="matchPassword(form.value.newPassword, form.value.confirmPassword)" matInput required
                                                                autocomplete="off">
                                                            <mat-error class="mt-10" *ngIf="f.newPassword?.errors?.required">
                                                                {{'DASHBOARD.SETTINGS.CP.NewPasswordIsRequired' | translate }} </mat-error>
                                                            <mat-error class="mt-10"
                                                                *ngIf="f.newPassword?.errors && !f.newPassword?.errors?.required && f.newPassword?.errors.minlength">
                                                                {{'DASHBOARD.SETTINGS.CP.NewPasswordIsMinlength' | translate }} </mat-error>
                                                            <mat-error class="mt-10"
                                                                *ngIf="f.newPassword?.errors && !f.newPassword?.errors?.required&& f.newPassword?.errors.maxlength">
                                                                {{'DASHBOARD.SETTINGS.CP.NewPasswordIsMaxlength' | translate }} </mat-error>
                                                            <password-strength-bar [passwordToCheck]="f.newPassword.value"></password-strength-bar>
                                                        </mat-form-field>
                                                    </div>
                                    
                                                    <div class="change-p-data m-0">
                                                        <mat-form-field class="example-full-width">
                                                            <mat-label> {{'DASHBOARD.SETTINGS.CP.ConfirmPassword' | translate }} </mat-label>
                                                            <input class="font-raleway" [minlength]=8 [maxlength]=20 type="password" placeholder="********"
                                                                formControlName="confirmPassword"
                                                                (blur)="matchPassword(form.value.newPassword, form.value.confirmPassword)" matInput required
                                                                autocomplete="off">
                                                            <mat-error class="mt-10" *ngIf="f.confirmPassword?.errors?.required">
                                                                {{'DASHBOARD.SETTINGS.CP.ConfirmPasswordIsRequired' | translate }} </mat-error>
                                                            <mat-error class="mt-10"
                                                                *ngIf="f.confirmPassword?.errors &&  !f.confirmPassword?.errors?.required && matchPasswordError">
                                                                {{'DASHBOARD.SETTINGS.CP.PasswordDoNotMatch' | translate }}</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                    
                                    
                                            <button type="submit" class="mt-20" save-btn
                                                [ngClass]="!form.invalid && !matchPasswordError ? 'save-btn' : 'save-btnDisable'">
                                                {{'AdditionalVarification.CONFIRM' | translate}} 
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> -->


                    </div>
                </div>
            </div>
    
        </div>

        <div class="body pt-30 p-30 av-100" *ngIf="isError && errorMessage">
            <div class="body" >
                <div class="container">
                    <div class="row">
                        <div  class="step-error-msg p-30 text-center">
                            <h3 class="color-red"> {{'AdditionalVarification.ERROR' | translate}} </h3>
                            <p> {{errorMessage}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="body pt-30 p-30 av-100" *ngIf="isSuccess">
            <div class="body" >
                <div class="container">
                    <div class="row">
                        <div  class="step-error-msg p-30 text-center">
                            <h3 class="color-green"> {{'AdditionalVarification.SUCCESS' | translate}} </h3>
                            <p> {{'AdditionalVarification.PasswordUpdated' | translate}} </p>
                            <button autofocus class="aa-green-btn" (click)="login()"> {{'REGISTER.AlertActivated.GoToLogin' | translate }} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </container>