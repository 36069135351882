<container *ngIf="isCodeValid">
    <div class="body width_100p">
        <div class="pt-30">
            <div class="form-container web-registration-form m-auto">
                <div class="form-header"> {{'HRTRANSACTION.HrTransactions' | translate}} </div> 
                <!-- <div class="mt-10" > {{cardNumberMsg}} </div><br/> -->
                <div class="mt-10 form-label-card" > {{ 'HRTRANSACTION.Header' | translate: {cardNumber: cardNumber } }} </div><br/>
             
               
                <form >
                    <div class="form-row">
                        <div class="col-4">
                            <label class="form-label" for="Amount">
                                {{'HRTRANSACTION.Amount' |
                                translate}}
                            </label>
                        </div>

                        <div class="col-8">
                            <label class="form-label-val" for="AmountVal">
                               $ {{hrData?.amt}}
                            </label>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-4">
                            <label class="form-label" for="TransactionDate">
                                {{'HRTRANSACTION.TransactionDate' |
                                translate}}
                            </label>
                        </div>

                        <div class="col-8">
                          <label class="form-label-val" for="TransactionDateVal">
                            {{hrData?.transTime}}
                          </label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-4">
                            <label class="form-label" for="MerchantName">
                               {{'HRTRANSACTION.MerchantName' |
                                translate}}
                            </label>
                        </div>

                        <div class="col-8">
                          <label class="form-label-val" for="MerchantNameVal">
                            {{hrData?.mercName}}
                          </label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-4">
                            <label class="form-label" for="MerchantLocation">
                                {{'HRTRANSACTION.MerchantLocation' |
                                translate}}
                            </label>
                        </div>

                        <div class="col-8">
                          <label class="form-label-val" for="MerchantLocationVal">
                            {{hrData?.mercLoc}}
                          </label>
                        </div>
                    </div>
                   
                    <div class="form-row">
                        <div class=" col-md-12 text-center pt-2 mt-10 mb-20">
                            <button type="button" class="mt-10 btn-padding login-green-btn" (click)="sendResponse(true)"  >{{'HRTRANSACTION.Yes'
                                | translate}}</button>
                            <button type="button" class="ca-cancel ml-20 mt-10 btn-padding" (click)="sendResponse(false)">{{'HRTRANSACTION.No'
                                | translate}} </button>
                        </div>
                    </div>

                </form>               
            </div>
        </div>
    </div>
</container>


<container *ngIf="!isCodeValid && errorMessage!=''">
    <div class="body width_100p">
      <div class="container">
        <div class="form-header pl-30 pt-30"> {{'HRTRANSACTION.HrTransactions' | translate}} </div>
        <div class="row">
          <div class="step-error-msg p-30">
            <!--<h3 *ngIf="isError" class="set-color-red"> {{'QUICKREGISTER.Error' | translate}} </h3>
            <h3 *ngIf="!isError" class="set-color-green"> {{'DOWNLOADFILE.Success' | translate}} </h3>-->
            <p class="p-30" [innerHtml]="errorMessage"></p>
          </div>
        </div>
      </div>
    </div>
</container>
