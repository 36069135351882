import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RestService } from '@services/rest.service';
import { AppConfiguration } from '@config/app-configuration';

@Injectable({
  providedIn: 'root'
})
export class HighRiskService {

  constructor(
    private restService: RestService,
    private httpClient: HttpClient,
    private appConfig: AppConfiguration,
  ) { }
  
 
  verifyCode(code:string, lang:string): Observable<any> {         
    let appId = this.appConfig.appId['web']; 
    const httpParams = new HttpParams() 
    .set('appId', appId)  
    .set('lang', lang)   ;  
    return this.restService.get<any>(`/api/cardholders/transactions/high-risk/${code}`, null, httpParams);  
  }

  identifyRisk(lang:string, body:any): Observable<any> {   
    let appId = this.appConfig.appId['web'];  
    const httpParams = new HttpParams() 
    .set('appId', appId)
    .set('body', body)
    .set('lang', lang)   ;       
    return this.restService.post<any>(`/api/cardholders/transactions/high-risk/identify`, body, null, httpParams);
  }
}
